import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <div>
    Página no encontrada
    <center><Link to="/">Regresar a la página principal</Link></center>
    </div>
);

export { NotFound };
