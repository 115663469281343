import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './services/PrivateRoute';
import './App.css';

//Components
import { HomePage } from './components/home/HomePage';

import { NotFound } from './components/notfound/NotFound';




function App() {
  return (
    <div>              
        <Router basename={process.env.PUBLIC_URL}>
                    <div>
                   
                        <Switch>              
                        	  <Route path="/" exact component={HomePage} />
                            <Route path="/:invite" exact component={HomePage} />
                            <Route path="/invite/:invite" exact component={HomePage} />
                            <Route component={NotFound} />
                        </Switch>
                        </div>
                </Router>
    </div>
  );
}

export default App;
