export const userService = {
    login,
    logout,
    isLogged,
    getDate,
    getTime,
    getFullTime,
    getUsername,
    getCategoryId,
    getUserId,
    getClientId,
    setClientId,
    setCategoryId,
    getAuth,
    getMonthName,
    getDateDayPlus,
    getDateMonthPlus,
    isValidDate,
    getMonths,
    getMonthNameByMonth,
    getParseDate,
    getMonthsList,
    getYears,
    isCurrentDate,
    parseDate,
    setCookie,
    getCookie,
    deleteCookie
};

function login(user) {
    localStorage.setItem('user', window.btoa(user.id));
    localStorage.setItem('type', window.btoa(user.type));
    localStorage.setItem('name', window.btoa(user.fullname));
    localStorage.setItem('key', window.btoa(user.clientid));
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    console.log('logout:',localStorage.getItem('user'));
}

function isLogged() {
    return localStorage.getItem('user');
}

function getUsername() {
    return window.atob(localStorage.getItem('name'));
}

function getCategoryId(id) {
    localStorage.setItem('category', window.btoa(id));
}

function getUserId() {
    return window.atob(localStorage.getItem('user'));
}

function getClientId() {
    return 'bdbef598-2954-403d-b6dd-1e3e20453d93'//window.atob(localStorage.getItem('key'));
}

function setClientId(clientId) {
    window.btoa(localStorage.setItem('key'));
}

function setCategoryId(id) {
    window.btoa(localStorage.setItem('key'));
}

function getAuth() {
    return null;//"df6d1444-103a-11eb-adc1-0242ac120002";//window.atob(localStorage.getItem('user'));
}

function decodeUser() {
    const userEncode = localStorage.getItem('user');
    const user = window.atob(userEncode);
    console.log("userDecode: " + user);
    console.log("userDecode: " + JSON.stringify(user));
    return user;
}

/** Move to utils class **/
function getFullTime() {
    return this.getDate() + " " + this.getTime();
}

function  getDate() {
    let date = new Date();
    let formatDate = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
    
    return formatDate;
}

function getTime() {
    let date = new Date();
    let formatTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    return formatTime;
}

function getDateDayPlus(datePayment, plus) {
    const splitDate = datePayment.split("-");
    const date = new Date(splitDate[0], splitDate[1], splitDate[2]);
    
    date.setDate(date.getMonth() + plus);
    let month = date.getMonth().toString().length == 1 ? "0" + date.getMonth() : date.getMonth();
    let day = date.getDate().toString().length == 1 ? "0" + date.getDate() : date.getDate();
    const formatDate = date.getFullYear() + "-" + month + "-" + day;
    
    return formatDate;
}

function getDateMonthPlus(datePayment, plus) {
    const splitDate = datePayment.split("-");
    const date = new Date(splitDate[0], splitDate[1], splitDate[2]);
    
    date.setMonth(date.getMonth() + plus);
    const formatDate = date.getFullYear() + "-" + (date.getMonth()) + "-" + date.getDate();
    
    return formatDate;
}

function isValidDate(datePayment) {
    return (new Date(datePayment) <= this.getDate());
}

function isCurrentDate(timestamp) {
    let date = new Date(timestamp);
    let formatDate = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
    return (formatDate == this.getDate());
}

function getMonthName() {
    const date = new Date();

    switch(date.getMonth()+1) {
        case 1: return "Enero";
        case 2: return "Febrero";
        case 3: return "Marzo";
        case 4: return "Abril";
        case 5: return "Mayo";
        case 6: return "Junio";
        case 7: return "Julio";
        case 8: return "Agosto";
        case 9: return "Septiembre";
        case 10: return "Octubre";
        case 11: return "Noviembre";
        case 12: return "Diciembre";
    }
}

function getMonths() {
    //generate months
	let initialMonth = 11;
    let initialYear = 2020;
    
    const date = new Date();
    const currentMonth = (date.getMonth()+1);
    const currentYear = date.getFullYear();

    const months = [];
    while(initialYear <= currentYear) {
        while(initialMonth <= currentMonth) {
            const month = {};
            month.value = initialYear + "" + initialMonth; 
            month.text = getMonthNameByMonth(initialMonth) + " " + initialYear;
            initialMonth++;
            months.push(month);
        }
        initialYear++;
    }

    return months;
}

function getMonthNameByMonth(month) {
    switch(month) {
        case 1: return "Enero";
        case 2: return "Febrero";
        case 3: return "Marzo";
        case 4: return "Abril";
        case 5: return "Mayo";
        case 6: return "Junio";
        case 7: return "Julio";
        case 8: return "Agosto";
        case 9: return "Septiembre";
        case 10: return "Octubre";
        case 11: return "Noviembre";
        case 12: return "Diciembre";
    }
}

function getMonthsList(month, language) {
    const months = [];
    months.push({"id" : "01", "name" : "Enero"});
    months.push({"id" : "02", "name" : "Febrero"});
    months.push({"id" : "03", "name" : "Marzo"});
    months.push({"id" : "04", "name" : "Abril"});
    months.push({"id" : "05", "name" : "Mayo"});
    months.push({"id" : "06", "name" : "Junio"});
    months.push({"id" : "07", "name" : "Julio"});
    months.push({"id" : "08", "name" : "Agosto"});
    months.push({"id" : "09", "name" : "Septiembre"});
    months.push({"id" : "10", "name" : "Octubre"});
    months.push({"id" : "11", "name" : "Noviembre"});
    months.push({"id" : "12", "name" : "Diciembre"});
    
    return months;
}

function  getParseDate() {
    let date = new Date();
    let strMonth;
    let strDay;
    
    if((date.getMonth()+1) < 10) {
        strMonth = '0' + (date.getMonth()+1);
    } else {
        strMonth = (date.getMonth()+1);
    }

    if(date.getDate() < 10) {
        strDay = '0' + date.getDate();
    } else {
        strDay = date.getDate();
    }

    const formatDate = date.getFullYear() + '-' + strMonth + '-' + strDay;
    
    return formatDate;
}

function getYears() {
    const years = [];
    let initialYear = 2020;
    const currentYear = new Date().getFullYear();
    while(initialYear <= currentYear) {
        const year = {};
        year.id = initialYear;
        year.name = initialYear;
        years.push(year);
        initialYear++;
    }

    return years;
}

function parseDate(date, split) {
    if(date) {
        const splits = date.split(split);
        return splits[2] + "/" + splits[1] + "/" + splits[0];
    }   
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

function deleteCookie(cookiename) {
    // remove session from cookie
    document.cookie = cookiename + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
}