import React from 'react';
import { API } from '../../services/constants';
import axios from 'axios';
import { userService } from '../../services';
import { v1 as uuidv1 } from 'uuid';
import './HomePage.css';
import imgHome from '../images/portada2.jpg'
import imgHome2 from '../images/portada5.jpg'
import liverpoolIcon from '../images/liverpool-2.png'
import amazonIcon from '../images/amazon.png'
import footerIcon from '../images/footer.jpeg'
import dressManIcon from '../images/bungalows.png'
import backgroundIcon2 from '../images/background5.jpg'
import hotel1 from '../images/hotel_7_cielos.png'
import dressManIcon2 from '../images/DRESSCODE2-men.jpg'
import dressWomanIcon2 from '../images/DRESSCODE-women.jpg'


//const API = "https://nomada.com.mx/apps/saving-box/webservices/";

class HomePage extends React.Component {
    constructor(props) {      
        super(props);
        this.state = {
          startingMinutes: 111,
          startingSeconds: 0,
          mins: 111,
          secs: 0,

          confirmed: false,
          name: null,
          number: null,
          message: null,
          invitesNumber: [
            {"id":"0", "value":"No asistiré"},
            {"id":"1", "value":"1 Invitado"}
          ],
          outputMessage: null,
          errorMessage: null,
          btnMessage: 'CONFIRMAR',
          invitesID: [
            '514c65910mxg',
            '11ee95fc6h8c',
            'e13a0c98b4d0',
            '57e5470ae8ee',
            '873add21kfer'
          ]
        };
  
        //this.chartRef = React.createRef();
        //this.canvasRef = React.createRef();
        //this.myChart = null;
    }

    updateStatus(item) {
      item.status = item.status == '1' ? '0' : '1';
      item.column = 'status';
      item.value = "'" + item.status + "'";
      axios.patch(API + 'ms-todo/v1/tasks.php', item).then(() => {
      //	this.refresh();
      })
      //newinventory = Object.assign({}, inventory);
      this.setState(Object.assign([], this.state.items));
    }

  async refresh() {
    const invite = this.props.match.params.invite
    const invitesNumber = this.state.invitesNumber

    //find position
    let index = 2
    let find = false
    for(const pos of this.state.invitesID) {
      if(invite === pos) {
        find = true
        break
      }
      index++
    }

    if(find) {
      let start = 2
      while(start <= index) {
        invitesNumber.push({"id":start, "value":start + " Invitados"})
        start++
      }
      this.setState({ invitesNumber })
    }
  }
  
  findMembresy(membresies, item) {
    for(const membresy of membresies) {
      if(item.membershipid == membresy.id) {
        return membresy;
      }
    }
    return null;
  }

  async createNode() {
      let node = {};
      node.name = "example";
      node.description = "desc";
      
      const res = await this.db.post({ ...node });
      this.refresh();
  }
  
  openSelection(id) {
    if(id == -1) {
      this.props.history.push("/category/0");
    } else {
      this.props.history.push("/categories/" + id);
    }
  }

  openPage(page) {
    this.props.history.push("/" + page);
  }

  componentDidMount() {
    this.refresh()
    this.timer()
  }

  componentDidUpdate() {
    //this.timer();
  }

  handleInput(e) {
    this.setState({'errorMessage':null, 'outputMessage':null})

    const { value, name } = e.target;
    this.setState({
      [name] : value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  
  async unsuscribe(item) {
    if(! window.confirm("Desea dar de Baja a " + item.name + " ?")) {
      return;
    }

    await axios.patch(API + 'ms-profile/v1/persons.php', {"id" : item.id, "column" : "active", "value" : 0 })
		.then(() => {
			this.refresh();
			this.setState({"alertMessage" : "Se realizaron los cambios correctamente!"});
      alert("Se ha dado de baja el alumno correctamente!");
		});
  }

  async pay(item) {
    if(! window.confirm("Desea pagar la Mensualidad de " + item.name + ", Costo: " + item.membresyValue + ".00 ?")) {
      return;
    }
  
    const transaction = {};
    transaction.id = uuidv1();
    transaction.clientid = userService.getClientId();
    transaction.ownerid = item.id;
    transaction.description = "Mensualidad "+userService.getMonthName()+ " de " + item.name;
    transaction.timestamp = userService.getFullTime();
    transaction.nextTimestamp = userService.getDatePlus(1);
    transaction.value = item.membresyValue;
    transaction.reference = item.membresy;
    transaction.type = "Ingreso";
    await axios.post(API + 'ms-transaction/v1/transactions.php', transaction)
      .then(res4 => {

      });
  
    axios.patch(API + 'ms-profile/v1/persons.php', { "id" : item.id, "column" : "date_payment", 
      "value" : "'" + userService.getDateMonthPlus(item.date_payment, 1) + "'"})
      .then(res4 => {
        alert("Se ha realizado el pago correctamente!");
        this.refresh();
      });
  }

  async confirmed() {
    //validations
    if(this.state.name == null) {
      this.setState({'errorMessage':'Favor de Ingresar su Nombre.'})
      return
    }

    if(this.state.number == null) {
      this.setState({'errorMessage':'Favor de Confirmar Asistencia y Número de Invitados.'})
      return
    }

    let already = false
    const item = {}
    item.id = uuidv1()
    item.name = this.state.name.trim().toUpperCase();
    item.invites = this.state.number
    item.confirmed = this.state.number == "0" ? false : true
    item.comments = this.state.message
    item.confirmed_date = userService.getFullTime()

    //check if name is already exists
    /*
    await axios.post(API + 'ms-todo/v2/invites-find.php/name', item)
      .then(res => {
        if(res.data.data != null) {
          already = true
        }
    })
    */
    if(already) {
      this.setState({'errorMessage':'Ya existe un registro de Confirmación para ' + this.state.name})
    } else {
      await axios.post(API + 'ms-todo/v2/invites-persons-z.php', item).then(() => { })

      if(item.confirmed) {
        this.setState({
          'confirmed':true, 
          'btnMessage':'Mensaje Enviado',
          'outputMessage':'¡Muchas Gracias '+this.state.name+' por Confirmar tu Asistencia! Te Esperamos!'})
      } else {
        this.setState({
          'confirmed':true, 
          'btnMessage':'Mensaje Enviado',
          'outputMessage':'Lamentamos que no nos puedas acompañar en el Evento pero muchas gracias por avisarnos!'})
      }
    }
  }

  timer() {
    let sampleInterval = setInterval(() => {
      let fecha=new Date('2024','2','16','17','30','00')
      //console.log('fecha: ' + fecha)
      
      //alert(fecha)
      let hoy=new Date()
      //console.log('hoy: ' + hoy)
      let days=0
      let hours=0
      let mins=0
      let secs=0

      let diferencia=(fecha.getTime()-hoy.getTime())/1000

      if(diferencia > 0) {
        //console.log("diff: " + diferencia)
        days=Math.floor(diferencia/86400)
        diferencia=diferencia-(86400*days)
        hours=Math.floor(diferencia/3600)
        diferencia=diferencia-(3600*hours)
        mins=Math.floor(diferencia/60)
        diferencia=diferencia-(60*mins)
        secs=Math.floor(diferencia)
      }
      

        this.setState({ days });
        this.setState({ hours });
        this.setState({ mins });
        this.setState({ secs });
      /*
      let secs = this.state.secs;
      let mins = this.state.mins;

      if (secs > 0) {
        secs = secs - 1;
        this.setState({ secs });
        //setSeconds(secs - 1);
      }
      if (secs === 0) {
        if (mins === 0) {
          clearInterval(sampleInterval);
        } else {
          mins--;
          secs = 59;
          this.setState({ mins });
          this.setState({ secs });
          //setMinutes(mins - 1);
          //setSeconds(59);
        }
      }
      */
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
    };
  }

  render() {

    /**
     * style={{"height": "100vh; background-image:url('../images/portada.jpg'); display: flex; justify-content: center; align-items: flex-end; background-position: 50% 50%; background-color: #FFF; -webkit-transform: translateZ(0); overflow: hidden;"}}>
     */
    return (
      <div>


<div style={{"height":"100%"}}>
 
  <div class="container containerCustom">


  <div class="row featurette" style={{textAlign: 'center'}}>

  <div id="homeslider-wrap" class="homesliderimage2" 
  style={{backgroundImage: `url("${imgHome}")`, height: "90vh",display: 'flex', justifyContent: 'center',alignItems: 'flex-end', 
    backgroundPosition: '63% 70%', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}>
  
 

<div id="countdown" class="vertical-center countdown-box">

<div class="row">

<div class="col-md-offset-1 col-md-12 carousel-caption2 animated-element-1 delay1 my-5 animated fadeIn">

<h2 class="margin personalColorTitutlo" style={{fontSize: '27px', marginTop: '10px', textAlign: 'center'}}>¡Nos Casamos! </h2>
<h1 class="titprin personalColorTitutlo" 
  style={{"font-family": "Verdana;", fontSize: '37px'}}> Zayra<span class="logo-slider personalColorTitutlo"><b> &amp; </b></span>Bryan</h1>

{/* 
<h2 class="margin personalColorTitutlo" style={{fontSize: '40px', marginTop: '20px'}}>Sábado </h2>
*/}
<h2 class="margin personalColorTitutlo" style={{fontSize: '33px', marginTop: '10px'}}>16.03.24</h2>

<div id="clock" class="personalColorTitutlo"><span class="wraper-clock">
  <div  style={{fontSize: '22px'}}class="value personalColorTitutlo">{this.state.days}</div>
  <div style={{fontSize: '22px'}} class="value-label">Días</div></span><span class="sep">:</span><span class="wraper-clock">
    <div style={{fontSize: '22px'}} class="value personalColorTitutlo">{this.state.hours}</div>
    <div style={{fontSize: '22px'}} class="value-label">Horas</div></span><span class="sep">:</span><span class="wraper-clock">
    <div style={{fontSize: '22px'}} class="value personalColorTitutlo">{this.state.mins}</div>
    <div style={{fontSize: '22px'}} class="value-label">Minutos</div></span><span class="sep">:</span><span class="wraper-clock">
    <div style={{fontSize: '22px'}} class="value personalColorTitutlo">{this.state.secs}</div>
    <div style={{fontSize: '22px'}} class="value-label">Segundos</div></span></div>
</div>
</div>
</div>
</div>
    </div>

    <div class="row featurette" style={{textAlign: 'center'}}>

    <div id="homeslider-wrap" class="homesliderimage2" 
  style={{backgroundImage: `url("${imgHome2}")`, height: "50vh",display: 'flex', justifyContent: 'center',alignItems: 'flex-end', 
    backgroundPosition: '45% 45%', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}>
      
      
      </div>

    </div>

{/*
style={{backgroundImage: `url("${backgroundIcon2}")`, height: "70vh",display: 'flex', justifyContent: 'center',alignItems: 'flex-end', 
    backgroundPosition: '45% 0%', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}
     */}
    <div class="row event-container" style={{backgroundImage: `url("${backgroundIcon2}")`, height: "60vh",
    display: 'flex', justifyContent: 'center',alignItems: 'flex-end', 
    backgroundPosition: '50% 40%', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}>
   
   <br />
          <br />

   <h4 style={{fontFamily: "Cursive", fontWeight: "bold", "padding-left":"50px", "padding-right":"50px"}}>
   Hemos decidido unir nuestras vidas en matrimonio y queremos que seas parte de Nuestra Celebración.
   </h4>


     {/* 
         <h4 
        class="margin personalColorTitutlo" 
        style={{"font-family": "Great Vibes !important;", "padding-left":"50px", "padding-right":"50px"}}>
          <br />
          Hemos decidido unir nuestras vidas en matrimonio y queremos que seas parte de Nuestra Celebración.
          <br />
          <br />
      </h4> 

    <h5 
        class="margin" 
        style={{"font-family": "Cursive;", "padding-left":"60px", "padding-right":"60px"}}>
          Hemos decidido unir nuestras vidas en matrimonio y queremos que seas parte de Nuestra Celebración.
      </h5>        
*/}

<div class="event-wrap ceremonia-wrap">
<h3 style={{fontFamily: "Cursive"}}>Misa</h3>
{/* 
<img 
  class="rounded-circle" src={iglesiaIcon} alt="Basilica" width="120" height="120" />
*/}

     
<p style={{fontSize: '15px'}}> <br />  <b>Parroquia "Del Señor de la Ascensión"</b><br />
57, Centro, Santa María del Oro, Nay.</p>
<div class="event-btn">
<div class="btn-grl">
16:00
</div>
<a target="_blank" class="event-hra" 
  href="https://maps.app.goo.gl/1VvQmyMKG8jX9Yb99">Ver Mapa</a>
</div>
<br />
</div>
<div class="event-wrap fiesta-wrap">
<h3 style={{fontFamily: "Cursive"}}>Recepción</h3>
{/*
<img class="rounded-circle" src={partyIcon} alt="Basilica" width="120" height="120" />
*/}

<p style={{fontSize: '15px'}}>
  <br />
  "Refugio del Cielo"<br />
Mirador laguna de santa maría del oro, Santa María del Oro, Nay.</p>
<div class="event-btn">
<div class="btn-grl">
17:30
</div>
<a target="_blank" class="event-hra" href="https://maps.app.goo.gl/95zioqV646nCy28e7">Ver Mapa</a>
</div>
<br />
</div>

</div>

<hr class="featurette-divider" />

    <form id="deseos" class="form-reservacion" onsubmit="return app.enviarConfirmacion(event)">
      
      <div class="row">
      <div class="text-center">
      <h4>Confirma tu asistencia</h4>
      <br />
      </div>

      <div class="text-center">
<div style={{"font-size":"24px;"}}> ¡Gracias por compartir este día tan especial con Nosotros!<br /><br />
{/*
Favor de confirmar su presencia antes del:<br /><br />
</div>
<div style={{ fontWeight: "bold"}}>11 Noviembre, 2023<br /><br />
 */}
</div>
<p style={{fontSize:"14px"}}>*Evento con adolescentes mayores de 12 años.<br /><br /></p>
</div>
{/* 
¡Muchas Gracias!
<p class="ninos">Evento con adolescentes mayores de 12 años.</p>
*/}
<div class="row">
<div class="col-md-12">
  <input 
    onChange={this.handleInput.bind(this)} 
    required="" type="text" placeholder="· Nombre completo" class="form-control" name="name" />
  <br />
</div>

</div>
<div class="row">
  <div class="col-md-6">
  <select 
    onChange={this.handleInput.bind(this)} 
    name="number" class="form-control" value={this.state.number}>
      <option value="" disabled="" selected="">· Invitados confirmados</option>
				{this.state.invitesNumber.map((item) => 
					<option value={item.id}>{item.value}</option>
				)}
	</select>

  <br />
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
  <textarea 
    onChange={this.handleInput.bind(this)} 
    type="text" placeholder="· Dedica un mensaje..." class="form-control" name="message"></textarea>
  <br />
</div>

{this.state.outputMessage ? 
  <MessageLabel txtLbl={this.state.outputMessage} colorText={"black"} />
  : null
}

{this.state.errorMessage ? 
  <MessageLabel txtLbl={this.state.errorMessage} colorText={"red"} />
  : null
}

</div>

<button 
  style={{'border':'none'}}
  type="button" 
  class="btn-grl" 
  disabled={this.state.confirmed}
  onClick={this.confirmed.bind(this)}>
    {this.state.btnMessage}
</button>

<div class="row">
<div class="text-center">
<div class="col-md-12">
<h2 style={{"color": "#ffffff !important;"}}>
<br />Contacto</h2>
<div class="frasesMax" style={{"color": "#ffffff !important;"}}>
<div style={{"font-size":"24px;"}}>También puede confirmar vía whatsapp<br /><br />¡Contamos contigo!</div>
<br />
</div>

<div class="col-lg-14">

<a target="_blank" href="https://api.whatsapp.com/send/?phone=3241054250&amp;text=Confirmo asistencia al evento de Zayra y Bryan">
<button type="button" class="btn btn-success btn-block">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
</svg>
{"   "}Confirmar con la Novia
</button>
</a>
<br />
<br />

<a target="_blank" href="https://api.whatsapp.com/send/?phone=3241084139&amp;text=Confirmo asistencia al evento de Zayra y Bryan">
<button type="button" class="btn btn-success btn-block">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
</svg>
{"   "}Confirmar con el Novio
</button>
</a>

</div>
</div>
</div>
</div>

</div>



</form>

<div class="col-xs-12">

<div class="about-section-wraper onepage text-center animated-element-1 animated fadeIn">


</div>

</div>

<hr class="featurette-divider" />

<div class="row featurette">
      <div class="container containerCustom" style={{ backgroundColor: '#ffffff' }}>
<section id="gift-registry" class="onepage">

<div class="text-center">
<br />
<h4>Dress Code Formal</h4>
<br />
</div>


</section>

<div class="row event-container">
  <div class="event-wrap ceremonia-wrap">
  <img style={{width:'25%'}} src={dressWomanIcon2} />
  <div style={{marginTop:'5px',marginBottom:'5px'}} class="titulo-recomendacion"></div>
  </div>

  <div class="event-wrap ceremonia-wrap">
  <img style={{width:'25%'}} src={dressManIcon2} />
  <div style={{marginTop:'5px',marginBottom:'5px'}} class="titulo-recomendacion"></div>
  </div>
</div>

<br />

</div>
    </div>

<hr class="featurette-divider" />

<div class="row featurette">
      <div class="container containerCustom" style={{ backgroundColor: '#ffffff' }}>
<section id="gift-registry" class="onepage">

<div class="text-center">
<br />
<h4>Opciones de Hospedaje</h4>
</div>
</section>

<div class="row event-container" style={{height: "45vh",
    display: 'flex', justifyContent: 'center',alignItems: 'flex-end', 
    backgroundPosition: '0% 0%', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}>
   
     {/* 
    <h5 
        class="margin" 
        style={{"font-family": "Cursive;", "padding-left":"60px", "padding-right":"60px"}}>
          Hemos decidido unir nuestras vidas en matrimonio y queremos que seas parte de Nuestra Celebración.
      </h5>        
*/}

<div class="event-wrap ceremonia-wrap">
<h3 style={{fontFamily: "Cursive"}}>Hotel Siete Cielos</h3>

<img 
  class="rounded-circle" src={hotel1} alt="Basilica" width="120" height="120" />


     
<p style={{fontSize: '15px'}}> <br />  <b>Tel: 33 1607 1029</b><br />
Domicilio: Calle principial en Bungalows Lucero, Santa María del Oro
</p>
<div class="event-btn">
<a target="_blank" class="event-hra" 
  href="https://www.invitelink.app/files/hoteles.pdf">Ver Más</a>
  <br />
</div>

</div>
<div class="event-wrap fiesta-wrap">
<h3 style={{fontFamily: "Cursive"}}>Bungalow Lucero</h3>

<img class="rounded-circle" src={dressManIcon} alt="Bungalows Lucero" width="120" height="120" />


<p style={{fontSize: '15px'}}>
  <br />
  Tel: 311 104 9467<br />
Domicilio: Minisuper Lucero, Conocido S/N, Santa María del Oro, Nayarit

</p>
<div class="event-btn">
<a target="_blank" class="event-hra" href="https://www.invitelink.app/files/hoteles.pdf">Ver Más</a>
<br />
</div>

</div>

</div>

</div>
    </div>

    <hr class="featurette-divider" />

    <div class="row featurette">

      <div class="container containerCustom" style={{ backgroundColor: '#ffffff' }}>
<section id="gift-registry" class="onepage">

<div class="text-center">
<br />
<h4>Mesa de Regalos</h4>

</div>

</section>

<div class="row event-container">
  <div class="event-wrap ceremonia-wrap">
  <img style={{width:'50%'}} src={liverpoolIcon} />
  <div style={{marginTop:'5px',marginBottom:'5px'}} class="titulo-recomendacion">No. de Evento: 51188817</div>
  <div style={{marginTop:'5px',marginBottom:'20px'}} class="titulo-recomendacion">{""}</div>
  <a style={{width:'50%'}} className="btn-grl" href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51188817" target="_blank">Comprar</a>
  </div>

  <div class="event-wrap ceremonia-wrap">
  <br />
  <img style={{width:'55%'}} src={amazonIcon} />
  <br />
  <br />
  <div style={{marginTop:'5px',marginBottom:'5px'}} class="titulo-recomendacion">No. de Evento: MG72DYP03769</div>
  <div style={{marginTop:'5px',marginBottom:'20px'}} class="titulo-recomendacion">{""}</div>
  <a style={{width:'50%'}} className="btn-grl" href="https://www.amazon.com.mx/wedding/registry/MG72DYP03769" target="_blank">Comprar</a>
  </div>
</div>





<br />
<br />

</div>
</div>


    <div class="row featurette">
     
{/* 
     <div class="container containerCustom" style={{ backgroundColor: '#ffffff' }}>
<section id="gift-registry" class="onepage">
<div class="text-center">
  <br />
     <p style={{"font-size":"30px;", 'padding-left':'35px', 'padding-right':'35px'}}>No olvides utilizar nuestro #hashtag de Instagram y etiquetarnos</p>

<div class="wrap-social">
<br />
<a target="_blank" class="filtro-social" href="https://www.instagram.com/explore/tags/noscasamosZayra&Bryan">
  <img src="https://miboda.love/endPoint/generador/recomendaciones/logos/instagram.svg" alt="Filtro Instagram" />
  {" "}#noscasamosZayra&Bryan</a>
 
</div>
<br />
</div>


</section>
</div>
*/}

   </div>

      <div>
      <div class="block-box-rsrv">

</div>
      </div>

      <div class="wraper-footer text-center animated-element-1 delay1 animated fadeIn" 
      style={{"bottom": "-30px;position: fixed;left: 50%;transform: translate(-50%, 0%);width: 100%;"}}>

<div class="margin">
<div id="homeslider-wrap" class="homesliderimage2" 
  style={{backgroundImage: `url("${footerIcon}")`, height: "100vh",display: 'flex', justifyContent: 'center',
  alignItems: 'flex-end', 
    backgroundPosition: '45% 60 %', backgroundColor: '#FFF', webkitTransform: 'translateZ(0)', overflow: 'hidden'}}>
      
<h1 
  style={{"font-size": "21px;", "font-family": "Verdana !important;"}}
  class="personalColorFraseFinal"><br /><b>¡Zayra &amp; Bryan!</b>
<br />
<br />
</h1>

</div>
</div>

</div>

      </div>


</div>

</div>
   );
	}
}

const MessageLabel = ({ txtLbl, colorText }) => (
	    <label htmlFor="" style={{ color: colorText }}>
	        <b>{txtLbl}</b>
	    </label>
	);

export { HomePage };